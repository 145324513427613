<template>
  <filter-interest-block
    module-name="influencerDiscovery"
    :show-audience="true"
    :applied-filters="appliedFilters"
    :is-filter-applied="isFilterApplied"
    @submit="handleSubmit"
    @update="handleUpdate"
    @remove="handleRemove"
  />
</template>

<script>
const FilterInterestBlock = () => import(/* webpackChunkName: "filter-interest" */ "@/blocks/common/filters/FilterInterest.vue")

export default {
  name: "FilterInterest",

  components: {
    FilterInterestBlock
  },

  computed: {
    // get a list of all of them
    appliedFilters() {
      return this.$store.getters['influencerDiscovery/findFiltersByType']("interest")
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters['influencerDiscovery/findFilterByType']("interest"))
    },
  },

  methods: {
    handleSubmit(data) {
      this.$store.dispatch('influencerDiscovery/addFilter', data)
    },

    handleUpdate(data) {
      this.$store.dispatch('influencerDiscovery/updateFilter', data)
    },

    handleRemove(data) {
      this.$store.dispatch('influencerDiscovery/removeFilter', data.id)
    }
  }
}
</script>
